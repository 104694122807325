<template>
    <section>
        <div class="container" id="roadmap">
            <div class="pt-5">
              <div class="mb-5 mb-lg-7 text-center text-md-left reveal">
                 <h2 class="h3-font font-w--600 mb-2 text-white">Roadmap</h2>
              </div>
                    <div class="timeline"> 
                        <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                            <div class="timeline__event__icon ">
                                <i class="lni-cake"></i>

                            </div>
                            <div class="timeline__event__date">
                                May, 2021
                            </div>
                            <div class="timeline__event__content ">
                                <div class="timeline__event__title">
                                    CryoWeb & CryoFuel Websites
                                </div>
                                <div class="timeline__event__description">
                                    <p>Token info website will be completed. &nbsp; <img src="/img/check.png" height="18px" /></p>
                                </div>
                            </div>
                        </div>

                        <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                            <div class="timeline__event__icon">
                                <i class="lni-burger"></i>
                            </div>
                            <div class="timeline__event__date">
                              May, 2021
                            </div>
                            <div class="timeline__event__content">
                                <div class="timeline__event__title">
                                  CryoWeb/CryoFuel Roadmap
                                </div>
                                <div class="timeline__event__description">
                                    <p>CryoWeb/CryoFuel Roadmap complete with a 1 year reaching vision. &nbsp; <img src="/img/check.png" height="18px" /></p>
                                </div>
                            </div>
                        </div>
                        <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                            <div class="timeline__event__icon">
                                <i class="lni-slim"></i>
                            </div>
                            <div class="timeline__event__date">
                               May, 2021
                            </div>
                            <div class="timeline__event__content">
                                <div class="timeline__event__title">
                                  CryoFuel White-Paper
                                </div>
                                <div class="timeline__event__description">
                                    <p>CryoFuel white-paper will be completed outlining in detail the direction of this token  &nbsp; <img src="/img/check.png" height="18px" /></p>
                                </div>
                            </div>
                        </div>
                        <div class="timeline__event animated fadeInUp timeline__event--type1">
                            <div class="timeline__event__icon">
                                <i class="lni-cake"></i>
                            </div>
                            <div class="timeline__event__date">
                                May, 2021
                            </div>
                            <div class="timeline__event__content">
                                <div class="timeline__event__title">
                                  CryoWeb/CryoFuel Logos
                                </div>
                                <div class="timeline__event__description">
                                    <p>CryoWebs & CryoFuels Logos will be finalized. All formats and sizes completed for TrustWallet, BSCScan, CoinMarketCap, CoinGecko and all other social media outlets.  &nbsp; <img src="/img/check.png" height="18px" /></p>
                                </div>
                            </div>
                      </div>
                      <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
                          <div class="timeline__event__icon ">
                              <i class="lni-cake"></i>

                          </div>
                          <div class="timeline__event__date">
                              May, 2021
                          </div>
                          <div class="timeline__event__content ">
                              <div class="timeline__event__title">
                                 Consumer Website
                              </div>
                              <div class="timeline__event__description">
                                  <p>The PHP/MySQL webiste that the token powers will be completed and in Beta Testing. This site is for the consumer end and where CryoWebs telecommute workforce will expend most of their efforts. &nbsp; <img src="/img/check.png" height="18px" /></p>
                              </div>
                          </div>
                      </div>
                      <div class="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                          <div class="timeline__event__icon">
                              <i class="lni-burger"></i>
                          </div>
                          <div class="timeline__event__date">
                            May, 2021
                          </div>
                          <div class="timeline__event__content">
                              <div class="timeline__event__title">
                               CryoWebs Business License
                              </div>
                              <div class="timeline__event__description">
                                  <p>You dont see this very often, but since CryoWeb is a business model there will be a business license applied for in the state that we are authorized to conduct business from. This will be made public online, we will also provide links once finalized. &nbsp; <img src="/img/check.png" height="18px" /></p>
                              </div>
                          </div>
                      </div>

                      <div class="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                          <div class="timeline__event__icon">
                              <i class="lni-slim"></i>
                          </div>
                          <div class="timeline__event__date">
                              May, 2021
                          </div>
                          <div class="timeline__event__content">
                              <div class="timeline__event__title">
                                How-To YouTube Videos
                              </div>
                              <div class="timeline__event__description">
                                  <p>CryoWeb will be adding how-to buy & sell videos to our YouTube channel. These will be CryoWeb specific. We feel we should not use other Tokens/Coins videos on how to buy CryoWeb. That makes sense to us.  &nbsp; <img src="/img/check.png" height="18px" /></p>
                              </div>
                          </div>
                      </div>
                      
                      <div class="timeline__event animated fadeInUp timeline__event--type1">
                            <div class="timeline__event__icon">
                                <i class="lni-cake"></i>
                            </div>
                            <div class="timeline__event__date">
                                May, 2021
                            </div>
                            <div class="timeline__event__content">
                                <div class="timeline__event__title">
                                 TAKE A BREAK!
                                </div>
                                <div class="timeline__event__description">
                                    <p>This is a lot to do in a short time. The dev and other team members will surely need a much deserved break. We will be in our official telegram channel meeting and conversing with our community members..To us that is taking a break with our new friends!</p>
                                </div>
                            </div>
                      </div>

                    </div>
                </div>
            </div>
         </section>
</template>


<style lang="scss" scoped>
$content-width: calc(40vw - 84px);
$margin: 20px;
$spacing: 20px;
$bdrs: 6px;
$circle-size: 40px;
$icon-size: 32px;
$bdrs-icon: 100%;

$color1: #9251ac;
$color2: #f6a4ec;

$color3: #87bbfe;
$color4: #555ac0;

$color5: #24b47e;
$color6: #aff1b6;
* {
  box-sizing: border-box;
}

h2 {
  margin: 5%;
  text-align: center;
  font-size: 2rem;
  font-weight: 100;
}
.timeline {
  display: flex;
  flex-direction: column;
  margin: $margin auto;
  position: relative;

  &__event {
    margin-bottom: $spacing;
    position: relative;
    display: flex;
    margin: $spacing 0;
    border-radius: $bdrs;
    align-self: center;
    width: 50vw;

    &:nth-child(2n + 1) {
      flex-direction: row-reverse;
      .timeline__event__date {
        border-radius: 0 $bdrs $bdrs 0;
      }
      .timeline__event__content {
        border-radius: $bdrs 0 0 $bdrs;
      }

      .timeline__event__icon {
        &:before {
          content: "";
          width: 2px;
          height: 100%;
          background: $color2;
          position: absolute;
          top: 0%;
          left: 50%;
          right: auto;
          z-index: -1;
          transform: translateX(-50%);
          animation: fillTop 2s forwards 4s ease-in-out;
        }
        &:after {
          content: "";
          width: 100%;
          height: 2px;
          background: $color2;
          position: absolute;
          right: 0;
          z-index: -1;
          top: 50%;
          left: auto;
          transform: translateY(-50%);
          animation: fillLeft 2s forwards 4s ease-in-out;
        }
      }
    }

    &__title {
      font-size: 1.2rem;
      line-height: 1.4;
      text-transform: uppercase;

      font-weight: 600;
      color: $color1;
      letter-spacing: 1.5px;
    }
    &__content {
      padding: $spacing;
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
      background: #fff;
      width: $content-width;
      border-radius: 0 $bdrs $bdrs 0;
    }
    &__date {
      color: $color2;
      font-size: 1.5rem;
      font-weight: 600;
      background: $color1;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      padding: 0 $spacing;
      border-radius: $bdrs 0 0 $bdrs;
    }
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color1;
      padding: $spacing;
      align-self: center;
      margin: 0 $spacing;
      background: $color2;
      border-radius: $bdrs-icon;
      width: $circle-size;
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
      padding: 40px;
      height: $circle-size;
      position: relative;

      i {
        font-size: $icon-size;
      }

      &:before {
        content: "";
        width: 2px;
        height: 100%;
        background: $color2;
        position: absolute;
        top: 0%;
        z-index: -1;
        left: 50%;
        transform: translateX(-50%);
        animation: fillTop 2s forwards 4s ease-in-out;
      }
      &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: $color2;
        position: absolute;
        left: 0%;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        animation: fillLeftOdd 2s forwards 4s ease-in-out;
      }
    }
    &__description {
      flex-basis: 60%;
    }

    &--type2 {
      &:after {
        background: $color4;
      }
      .timeline__event__date {
        color: $color3;
        background: $color4;
      }

      &:nth-child(2n + 1) {
        .timeline__event__icon {
          &:before,
          &:after {
            background: $color3;
          }
        }
      }

      .timeline__event__icon {
        background: $color3;
        color: $color4;
        &:before,
        &:after {
          background: $color3;
        }
      }
      .timeline__event__title {
        color: $color4;
      }
      .timeline__event__title {
      }
    }

    &--type3 {
      &:after {
        background: $color5;
      }
      .timeline__event__date {
        color: $color6;
        background-color: $color5;
      }

      &:nth-child(2n + 1) {
        .timeline__event__icon {
          &:before,
          &:after {
            background: $color6;
          }
        }
      }

      .timeline__event__icon {
        background: $color6;
        color: $color5;
        &:before,
        &:after {
          background: $color6;
        }
      }
      .timeline__event__title {
        color: $color5;
      }
      .timeline__event__title {
      }
    }
    &:last-child {
      .timeline__event__icon {
        &:before {
          content: none;
        }
      }
    }
  }
}

@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
    align-self: center;
    &__content {
      width: 100%;
    }

    &__icon {
      border-radius: $bdrs $bdrs 0 0;
      width: 100%;
      margin: 0;
      box-shadow: none;

      &:before,
      &:after {
        display: none;
      }
    }
    &__date {
      border-radius: 0;
      padding: $spacing;
    }

    &:nth-child(2n + 1) {
      flex-direction: column;
      align-self: center;

      .timeline__event__date {
        border-radius: 0;
        padding: $spacing;
      }

      .timeline__event__icon {
        border-radius: $bdrs $bdrs 0 0;
        margin: 0;
      }
    }
  }
}

@keyframes fillLeft {
  100% {
    right: 100%;
  }
}

@keyframes fillTop {
  100% {
    top: 100%;
  }
}

@keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}

</style>