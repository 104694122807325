<template>
    <section class="section--darkblue bg-gradient--darkblue--5">
      
            <!-- end of document area -->
            <div class="space faq faq--v1" id="faq">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="text-center text-sm-left mb-5 mb-lg-7 reveal">
                                <h2 class="h3-font font-w--600 mb-2">Frequently <br> asked questions</h2>
                            </div>
                        </div>
                        <!-- end of section title col -->
                        <div class="col-12 col-lg-8">
                            <div class="accordion accordion--v1 reveal remove-space--bottom" id="accordion1">
                                <div class="card mb-1">
                                    <button class="btn btn-header btn-link" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                        <span class="h6-font text-color--200 text-left font-w--600">CryoWeb/CryoFuel In Under A Minute ?</span>
                                        <i class="icon icon-up-arrow"></i>
                                    </button>
                                    <div id="collapse1" class="collapse" data-parent="#accordion1">
                                        <div class="card-body pl-3">
                                            <p class="text-color--500">
                                                CryoWebs token (CryoFuel) will be the currency that powers our end-user website CryoWeb.net. This use case ready end user site will be a full featured php/mysql website that allows clients to place a "Remembrance" profile of their passed loved ones on-line and on the block-chain (Binance Smart Chain).

As of now, our loved ones may get placed on a website from the service that facilitated their online obituary, but what happens if that establishment closes? Then their website (usually a subpar one at best) will go away forever.

Using the power of the internet, coupled with block-chain your loved ones will have a permanent place of remembrance. A place where yourself, family and friends can interact and all place "Remembrances of that special loved one..Imagine seeing pictures and videos that you never knew existed?

Further, NOW you can commission one of our talented NFT (Non-Fungible Token) Artists to create a special "In Memorium NFT"! Our creative team will "Mint" the embodiment of what your loved one meant to you and your family! This NFT will live FOREVER on the block-chain and can be shared with other family members to keep and cherish straight to teir mobile phone or computer. We even have a music producer that can compose the perfect sound to accompany the token!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                    
                                <!-- end of single accordion-->
                                <div class="card mb-1">
                                    <button class="btn btn-header btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                        <span class="h6-font text-color--200 text-left font-w--600">CryoFuel Tokenomics</span>
                                        <i class="icon icon-up-arrow"></i>
                                    </button>
                                    <div id="collapse5" class="collapse" data-parent="#accordion1">
                                        <div class="card-body pl-3">
                                            <p class="text-color--500">
                                                <div>CryoFuel rewards holders and pays for itself!</div>

                                               <div> Each transaction is taxed 10%.</div>
                                                <div>(buy, sell, wallet transfer)</div>

                                               <div> 5% goes to holders</div>

                                                <div>5% goes to the CryoFuels liquidity pool</div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- end of single accordion-->
                                <div class="card mb-1">
                                    <button class="btn btn-header btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                        <span class="h6-font text-color--200 text-left font-w--600">CryoFuel Social Links </span>
                                        <i class="icon icon-up-arrow"></i>
                                    </button>
                                    <div id="collapse6" class="collapse" data-parent="#accordion1">
                                        <div class="card-body pl-3">
                                            <p class="text-color--500">
                                                <center>
                                                    <a href="https://t.me/cryowebtoken" target="_blank">
                                                    <button type="button" class="btn btn-outline-primary btn-sm"><i class="fa fa-telegram" aria-hidden="true"></i>TeleGram</button></a>
                                                    <br><br>
                                                    <a href="https://www.instagram.com/cryoweb/" target="_blank">
                                                    <button type="button" class="btn btn-outline-primary btn-sm"><i class="fa fa-instagram" aria-hidden="true"></i>InstaGram</button></a>
                                                    <br><br>
                                                    <a href="https://www.tiktok.com/@cryowebofficial?lang=en" target="_blank">
                                                    <button type="button" class="btn btn-outline-primary btn-sm"><i class="fa fa-instagram" aria-hidden="true"></i>TikTok</button></a>
                                                    <br><br>
                                                    <a href="https://github.com/CryoWebToken" target="_blank">
                                                    <button type="button" class="btn btn-outline-primary btn-sm"><i class="fa fa-github" aria-hidden="true"></i>GitHub</button></a>
                                                </center>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- end of single accordion-->
                            </div>
                        </div>
                    </div>
                    <!-- end of FAQ row -->
                </div>
                <!-- end of FAQ container -->
            </div>
            <!-- end of FAQ Area -->

        </section>
</template>