<template>
   <footer class="footer section--darkblue bg-gradient--darkblue--6">
            <div class="space--top pb-6 pb-lg-10 border--bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-9 col-lg-5 pr-lg-7 mb-4 mb-lg-0">
                            <span class="mb-2">
                                <img src="logo.png" alt="brand-logo">
                            </span>
                            <p></p>
                        </div>
                        <!-- end of col -->
                        <div class="col-6 col-md-4 col-lg-2 mb-1 mb-lg-0">
                            <h3 class="font-size--20 text-color--300 mb-2 font-w--700"></h3>
                            <ul class="list-unstyled">
                                <li><a href="#presale" class="text-color--400 body-font">Presale</a></li>
                                <li><a href="#team" class="text-color--400 body-font">Team</a></li>
                            </ul>
                        </div>
                        <!-- end of widget col -->
                        <div class="col-6 col-md-4 col-lg-2 mb-1 mb-lg-0">
                            <h3 class="font-size--20 text-color--300 mb-2 font-w--700"></h3>
                            <ul class="list-unstyled">
                                <li><a href="#features" class="text-color--400 body-font">Features</a></li>
                                <li><a href="#roadmap" class="text-color--400 body-font">Roadmap</a></li>
                            </ul>
                        </div>
                        <!-- end of widget col -->
                        <div class="col-6 col-md-4 col-lg-3 mb-1 mb-lg-0">
                            <h3 class="font-size--20 text-color--300 mb-2 font-w--700"></h3>
                            <ul class="list-unstyled">
                                <li><a href="#referral" class="text-color--400 body-font">Referral</a></li>
                            </ul>
                        </div>
                        <!-- end of col -->
                    </div>
                    <!-- end of row -->
                </div>
                <!-- end of main footer container -->
            </div>
            <!-- end of main footer area -->
            <div class="py-3">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <ul class="icon-group icon-rounded icon-rounded-color--primary justify-content-center">
                                <li><a href="https://github.com/CryoWebToken" class="color--primary"><i class="fab fa-github"></i></a></li>
                                <li><a href="https://t.me/cryowebtoken" class="color--primary"><i class="fab fa-telegram-plane"></i></a></li>
                                <li><a href="#" class="color--primary"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" class="color--primary"><i class="fab fa-twitter"></i></a></li>
                          
                            </ul>
                            <p class="text-color--500 font-size--14">© 2021 <a href="#" class="text-color--500">CryoFuel</a>. All Rights Reserved.</p>
                        </div>
                    </div>
                    <!-- end of mini footer row -->
                </div>
                <!-- end of mini footer container -->
            </div>
            <!-- end of mini footer area -->
        </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>