<template>
    <section class="space section--darkblue bg-gradient--darkblue--4">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-9 mx-auto mx-md-0">
                    <div class="mb-5 mb-lg-7 text-center text-md-left reveal">
                        <h2 class="h3-font font-w--600 mb-2">Features</h2>
                        <p class="h6-font">CryoFuel aims to power some of the amazing product and service on the CryoWeb platform.</p>
                    </div>
                </div>
            </div>
            <!-- end of section title row -->
            <div class="position-relative">
                <div class="vertical-border d-flex pos-abs-center h-100 w-100">
                    <span class="column-border"></span>
                </div>
                <div class="row horizontal-border justify-content-between mb-lg-5 pb-lg-5 reveal">
                    <div class="col-12 col-md-6 col-lg-5">
                        <div class="d-lg-flex mb-4 mb-lg-0 text-center text-md-left">
                            <span class="mb-3 mb-lg-0 mr-md-3">
                                <img src="img/icon-decentralized.svg" alt="icon">
                            </span>
                            <div>
                                <h3 class="h5-font text-color--200 font-w--600 mb-2">Mobile App</h3>
                                <p class="text-color--500">
                                    CryoWebs mobile app is in developent and should be released in the Fall of 2021. Allowing users to easily use their smart phone to interact on the CryoWeb platform.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- end of single item col -->
                    <div class="col-12 col-md-6 col-lg-5">
                        <div class="d-lg-flex mb-4 mb-lg-0 text-center text-md-left">
                            <span class="mb-3 mb-lg-0 mr-md-3">
                                <img src="img/icon-p2p.svg" alt="icon">
                            </span>
                            <div>
                                <h3 class="h5-font text-color--200 font-w--600 mb-2">Secure Storage</h3>
                                <p class="text-color--500">
                                    CryoWeb is hosted on a premiere datacenter that is secured with electronic access control and physical security to ensure that CryoWebs hardware and data is as secure as possible. Their state-of-the-art-network is powered by Brocade routers and Juniper & Cisco Switches.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- end of single item col -->
                </div>
                <!-- end of content row -->
                <div class="row horizontal-border justify-content-between mb-lg-5 pb-lg-5 reveal">
                    <div class="col-12 col-md-6 col-lg-5">
                        <div class="d-lg-flex mb-4 mb-lg-0 text-center text-md-left">
                            <span class="mb-3 mb-lg-0 mr-md-3">
                                <img src="img/icon-wallet.svg" alt="icon">
                            </span>
                            <div>
                                <h3 class="h5-font text-color--200 font-w--600 mb-2">Multicurrency Wallet</h3>
                                <p class="text-color--500">
                                    CryoWeb suggests using TrustWallet or MetaMask for your wallet of choice. We will add tutorials for each wallet shortly, and are always available thru our telegram channel
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- end of single item col -->
                    <div class="col-12 col-md-6 col-lg-5">
                        <div class="d-lg-flex mb-4 mb-lg-0 text-center text-md-left">
                            <span class="mb-3 mb-lg-0 mr-md-3">
                                <img src="img/icon-ledger.svg" alt="icon">
                            </span>
                            <div>
                                <h3 class="h5-font text-color--200 font-w--600 mb-2">Insurance Protection</h3>
                                <p class="text-color--500">
                                    The future of CryoWeb is secured by CryoFuel which rewards holders of the token and also powers our marketing & development fees. Development fees also pay our global telecommute work-force of data entry clerks.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- end of single item col -->
                </div>
                <!-- end of content row -->
            </div>
            <!-- end of benefits row -->
        </div>
      
    </section>
</template>

<style lang="scss">
    .short_height{ height: 120px !important; overflow-y: hidden; text-overflow: ellipsis; }
    .eight-feature-box:hover .short_height {
        height: 330px !important
    }
</style>