<template>
    <!-- =========== Start of Hero ============ -->
    <section class="hero hero--v6 hero--full section--darkblue bg-gradient--darkblue--1 d-flex align-items-center hidden">
        <div class="background-holder background--bottom z-index1">
            <img src="img/layout/map-1.png" alt="image" class="background-image-holder">
        </div>
        <!-- end of overlay bg -->
        <div class="svg-shape svg-shape-wave d-none d-lg-block">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 620">
                <defs>
                    <linearGradient id="wave-1-a" x1="91.821%" x2="8.258%" y1="-3.695%" y2="81.178%">
                    <stop offset="0%" stop-color="#2A36AC"/>
                    <stop offset="100%" stop-color="#171883"/>
                    </linearGradient>
                    <linearGradient id="wave-1-b" x1="90.535%" x2="10.832%" y1="4.232%" y2="85.759%">
                    <stop offset="0%" stop-color="#7679EC" stop-opacity="0"/>
                    <stop offset="100%" stop-color="#5659E1"/>
                    </linearGradient>
                </defs>
                <path class="svg-line" fill="url(#wave-1-a)" fill-rule="evenodd" stroke="url(#wave-1-b)" stroke-width="6" d="M-22.367223,829.365369 C47.8180949,725.233038 134.111268,731.437531 234.193598,847.152817 C273.353512,891.087195 307.028199,910.593177 338.265123,909.853439 C365.092805,909.218118 390.288083,894.182042 420.454093,863.630787 C429.588386,854.379841 438.997249,843.964548 451.335914,829.645952 C453.858532,826.718541 476.633091,799.992904 483.603802,791.987122 C504.948033,767.473516 522.234002,749.459356 540.435668,733.563857 C562.788651,714.043017 585.163058,699.16629 608.833467,688.824275 C664.49258,664.505837 726.433205,665.853427 799.302255,697.505792 C874.685922,730.250439 930.021659,735.252444 973.79212,717.251707 C992.450868,709.578239 1009.12469,697.759955 1025.59014,681.307946 C1038.52265,668.385995 1049.41242,655.028513 1067.90797,630.411498 C1069.06331,628.873768 1074.30586,621.882493 1075.73545,619.980561 C1094.31817,595.258063 1105.7581,581.219491 1119.4406,567.377636 C1137.08772,549.52499 1155.23692,536.056623 1175.64738,526.508862 C1223.79391,503.986512 1284.04516,503.894195 1364.18517,530.10651 C1441.86592,555.514446 1503.30729,554.203957 1555.08071,530.306348 C1577.09537,520.14481 1597.32891,505.962764 1617.26372,487.437577 C1633.34022,472.497869 1648.09715,456.026992 1667.76056,431.934359 C1670.72185,428.306027 1689.78833,404.658967 1695.30396,397.965487 C1712.53689,377.052492 1725.79585,362.613652 1739.58866,350.465124 C1756.33384,335.716199 1772.9761,325.393365 1790.81046,319.319361 C1831.54878,305.444757 1878.51106,314.004559 1937.4923,348.861006 C2123.16473,458.588806 2144.25888,666.20199 2001.45303,970.525832 L2000.65244,972.231901 L-125.452084,994.056123 L-22.422471,829.450405 L-22.367223,829.365369 Z" transform="translate(0 -310)"/>
            </svg>
        </div>
        <!-- end of svg shape -->
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-10 col-lg-8 col-xl-7 mx-auto mx-lg-0 mb-3 mt-8 mt-lg-0 text-center text-lg-left z-index2">
                    <div class="hero-content">
                        <h1 class="hero__title font-w--600 mb-2 mb-lg-3">NFT Based Utility Token for CryoWeb</h1>
                        <p class="hero__description text-color--200 font-size--20 opacity--80 mb-3 mb-lg-5 mb-md-6">
                            CryoWebs token (CryoFuel) will be the currency that powers our end-user website CryoWeb.net. 
                            This use case ready end user site will be a full featured php/mysql website that allows clients 
                            to place a "Remembrance" profile of their passed loved ones on-line and on the block-chain (Binance Smart Chain).
                        </p>

                        <div class="button-group d-flex flex-column flex-sm-row align-items-center d-sm-inline-block">
                            <a href="#" class="btn btn-bg--primary rounded--full btn-hover--3d btn-hover--splash">
                                <span class="btn__text">Contribute Now</span>
                            </a>

                            <a href="https://cryowebtoken.com/cryoweb_token_whitepaper.pdf" class="btn btn-bg--darkblue rounded--full btn-hover--3d btn-hover--splash">
                                <span class="btn__text">Whitepaper</span>
                            </a>
                        </div>
                    </div>
                    <!-- end of content -->
                </div>
                <!-- end of col -->

                <div class="col-12 col-md-1 pos-abs-md-vertical-center pos-right text-center  mr-md-3 mr-xl-2 z-index1">
                    <ul class="icon-group icon-group--vertical rounded--full justify-content-center mb-3 mb-md-10">
                        <li><a href="https://t.me/cryowebtoken" class="color--primary"><i class="fab fa-telegram-plane"></i></a></li>
                        <li><a href="https://twitter.com/CryoWebOfficial" class="color--primary"><i class="fab fa-twitter"></i></a></li>
                    </ul>
                </div>
                <!-- end of social icon -->

                <div class="col-xl-5 col-lg-7 position-relative pos-abs-xl-bottom-right z-index1 col-12 pb-md-5 pr-3 text-left">
                    <div class="ico-progress ico-progress--v1">
                        <div class="text-center text-lg-left">
                            <span class="font-size--20 color--white mb-1">Presale Ends In:</span>
                            <ul class="countdown d-flex mb-4 justify-content-center justify-content-lg-start text-left" id="date" :data-date="presaleEndDate">

                                <li class="d-flex flex-column mr-2 mr-sm-3">
                                    <span class="countdown__count h2-font font-w--500 mb-1" id="days"></span>
                                    <span class="color--primary font-w--500 opacity--60">Days</span>
                                </li>

                                <li class="d-flex flex-column mr-2 mr-sm-3">
                                    <span class="countdown__count h2-font font-w--500 mb-1" id="hours"></span>
                                    <span class="color--primary font-w--500 opacity--60">Hours</span>
                                </li>

                                <li class="d-flex flex-column mr-2 mr-sm-3">
                                    <span class="countdown__count h2-font font-w--500 mb-1" id="minutes"></span>
                                    <span class="color--primary font-w--500 opacity--60">Minutes</span>
                                </li>

                                <li class="d-flex flex-column">
                                    <span class="countdown__count h2-font font-w--500 mb-1" id="seconds"></span>
                                    <span class="color--primary font-w--500 opacity--60">Seconds</span>
                                </li>
                            </ul>
                        </div>
                        <!-- end of countdown -->
                        <div class="ico-progress-bar rounded--full d-flex align-items-center mb-2">
                            <div class="ico-progress-bar__inner rounded--full bg-color--primary jsElement" :data-progress-horizon="percentageSold"></div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="ico-progress__text">
                                <span class="body-font color--primary opacity--70 font-w--500">Sold</span>
                                <p class="text-color--300 h6-font font-w--700">{{totalTokensSold}} {{tokenSymbol}}</p>
                            </div>
                            <div class="ico-progress__text text-right">
                                <span class="body-font color--primary opacity--70 font-w--500">Remaining</span>
                                <p class="text-color--300 h6-font font-w--700">{{remainingTokens}} {{tokenSymbol}}</p>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- end of progress -->
            </div>
            <!-- end of row -->
        </div>

    </section>
    <!-- =========== End of Hero ============ -->
</template>

<script> 

import appConfig_ from '../config/app'
import contractAbi from "../data/contractAbi.json";


import { utils as ethersUtils, Contract, providers as ethersProviders, BigNumber } from "ethers";

export default {
    data(){
        return {
            totalTokensSold: 0,
            remainingTokens: 0,
            percentageSold: 0,
            presaleEndDate: appConfig_.presaleEndDate,
            tokenSymbol: appConfig_.tokenSymbol
        }
    },

    beforeMount(){
        this.computeProgressBarInfo();
    },

    methods: {

        async computeProgressBarInfo(){
            
            try{
                let provider = new ethersProviders.JsonRpcProvider(appConfig_.publicRPC);

                let contractInstance = new Contract( appConfig_.contractAddress , contractAbi , provider );

                //lets get stats 
                let assetsData = await contractInstance.getStats();

                let amountReleased =  assetsData[0];
                let amountRemaining =  assetsData[1];

                let totalPresaleAMount = amountReleased.add(amountRemaining);
                
                if(totalPresaleAMount.eq(BigNumber.from(0))){
                    return false;
                }

            
                
                let percentageReleasedBN = (amountReleased.mul(BigNumber.from(100))).div(totalPresaleAMount)

                this.percentageSold = this.toHumanReadable(percentageReleasedBN).toNumber();

                this.totalTokensSold = this.toHumanReadable(amountReleased).toString();

                this.remainingTokens = this.toHumanReadable(amountRemaining).toString();
                
            } catch (e){
                console.log("HeaderHero::computeProgressBarInfo", e, e.stack);
            }
            
        }

    }
}
</script>
