<template>
    <section class="space section--darkblue bg-gradient--darkblue--1 team team--v1" id="team">
        <div class="pb-6 pb-lg-10">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-10 col-md-8 mx-auto reveal">
                        <div class="text-center mb-5 mb-lg-7">
                            <h2 class="h3-font font-w--600 mb-2">Our team</h2>
                        </div>
                    </div>
                </div>
                <!-- end of section title row -->
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                        <div class="card card-hover--3d align-items-center border--none text-center pt-5 reveal">
                            <img src="team/joey_stubblefield.png" alt="mamber" height="180" width="180">
                            <div class="card-body align-items-center">
                                <h3 class="h6-font font-w--600 color--primary">Joey Stubblefield</h3>
                                <span class="font-size--14 opacity--80 font-w--500">Founder/Lead Developer</span>
                                <ul class="icon-group justify-content-center my-1">
                                    <li><a href="https://www.facebook.com/JoeyAStubblefield" class="color--primary opacity--70 px-1"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://twitter.com/CryoWebToken" class="color--primary opacity--70 px-1"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/joey-stubblefield" class="color--primary opacity--70 px-1"><i class="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- end of col -->
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                        <div class="card card-hover--3d align-items-center border--none text-center pt-5 reveal">
                            <img src="team/noah_barend.png" alt="mamber" height="180" width="180">
                            <div class="card-body align-items-center">
                                <h3 class="h6-font font-w--600 color--primary">Noah Barend</h3>
                                <span class="font-size--14 opacity--80 font-w--500 text-ellipses">CEO Of Smart Tokens</span>
                                <ul class="icon-group justify-content-center my-1">
                                    <li><a href="https://www.facebook.com/noahmmb" class="color--primary opacity--70 px-1"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://twitter.com/noahbarend?lang=en" class="color--primary opacity--70 px-1"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/joey-stubblefield" class="color--primary opacity--70 px-1"><i class="fab fa-linkedin-in"></i></a></li>   
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- end of col -->
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                        <div class="card card-hover--3d align-items-center border--none text-center pt-5 reveal">
                            <img src="team/miranda.png" alt="mamber" height="180" width="180">
                            <div class="card-body align-items-center">
                                <h3 class="h6-font font-w--600 color--primary">Mirranda Vanden Berg</h3>
                                <span class="font-size--14 opacity--80 font-w--500">Director Events/Marketing</span>
                                <ul class="icon-group justify-content-center my-1">
                                    <li><a href="#" class="color--primary opacity--70 px-1"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#" class="color--primary opacity--70 px-1"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/mirranda-vanden-berg-218b71214/" class="color--primary opacity--70 px-1"><i class="fab fa-linkedin-in"></i></a></li>   
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- end of col -->
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                        <div class="card card-hover--3d align-items-center border--none text-center pt-5 reveal">
                            <img src="team/adam_minarovich.png" alt="mamber" height="180" width="180">
                            <div class="card-body align-items-center">
                                <h3 class="h6-font font-w--600 color--primary">Adam Minarovich</h3>
                                <span class="font-size--14 opacity--80 font-w--500">Media Relations Director</span>
                                <ul class="icon-group justify-content-center my-1">
                                    <li><a href="https://en.wikipedia.org/wiki/Adam_Minarovich" class="color--primary opacity--70 px-1"><i class="fab fa-wikipedia-w"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/adam-minarovich-77388458/" class="color--primary opacity--70 px-1"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.imdb.com/name/nm1213539/" class="color--primary opacity--70 px-1"><i class="fab fa-imdb"></i></a></li>   
                                    <li><a href="https://www.linkedin.com/in/adam-minarovich-77388458/" class="color--primary opacity--70 px-1"><i class="fab fa-linkedin-in"></i></a></li>   
                               </ul>
                            </div>
                        </div>
                    </div>
                    <!-- end of col -->
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                        <div class="card card-hover--3d align-items-center border--none text-center pt-5 reveal">
                            <img src="team/kelley.png" alt="mamber" height="180" width="180">
                            <div class="card-body align-items-center">
                                <h3 class="h6-font font-w--600 color--primary">Kelley Wilson Robinson</h3>
                                <span class="font-size--14 opacity--80 font-w--500">Director, Branding/Promotion</span>
                                <ul class="icon-group justify-content-center my-1">
                                    <li><a href="https://www.facebook.com/madmanmom" class="color--primary opacity--70 px-1"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://twitter.com/madmanmom" class="color--primary opacity--70 px-1"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/kelley-robinson-40529a145/" class="color--primary opacity--70 px-1"><i class="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="https://www.imdb.com/name/nm7958785/" class="color--primary opacity--70 px-1"><i class="fab fa-imdb"></i></a></li>   
                                    <li><a href="https://www.instagram.com/madmanmom/" class="color--primary opacity--70 px-1"><i class="fab fa-instagram"></i></a></li>   
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- end of col -->
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-3">
                        <div class="card card-hover--3d align-items-center border--none text-center pt-5 reveal">
                            <img src="team/aro.png" alt="mamber" height="180" width="180">
                            <div class="card-body align-items-center">
                                <h3 class="h6-font font-w--600 color--primary">Arkadiusz Staroń</h3>
                                <span class="font-size--14 opacity--80 font-w--500">Director Social Media</span>
                                <ul class="icon-group justify-content-center my-1">
                                    <li><a href="https://twitter.com/ArkadiuszStaron" class="color--primary opacity--70 px-1"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/arkadiuszstaron" class="color--primary opacity--70 px-1"><i class="fab fa-linkedin-in"></i></a></li>   
                                </ul>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    
        <!-- end of advisory container -->
    </section>
</template>