export default {
    
    appName: "Cryo Fuel",
    
    presaleEndDate: "Jul 14, 2021",

    pricePerAirdropInBNB: 0.002,

    numberOfTokensPerBNB: 50_000_000_000,

    minBuyAmountInBNB: 0.1,

    tokenSymbol: "CRYO",

    tokenDecimal: 18,

    // if referral is not available, use this
    defaultReferralAddres: "0x7c588a10a4f0C39b71e92cf10f276914728e9E1A",

    // contract address
    contractAddress: "0xd6E52C8bD0278fa55255c1a5F67b022afbCA9d1b",

    supportedChainId: 0x38, // 56 is Binance Smart chain

    // comment this if not on testnet
    //supportedChainId: 0x61,//kovan for testing

    publicRPC: "https://bsc-dataseed.binance.org/"

}
